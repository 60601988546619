import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import Navbar from "../../component/common/NavbarProfile";
import batIcon from "../../assets/images/bat-icon.png";
import Contact from "../../assets/images/iconamoon_profile-thin.png";
import Email from "../../assets/images/iconamoon_email-thin.png";
import Phone from "../../assets/images/ph_phone-thin.png";
import { handleGetProductDetails } from "../../handlers/product/api";
import { toast } from "react-toastify";
import BackButton from "../../assets/images/backBtn.svg";
import { useLocation } from "react-router-dom";

const ItemDetailsPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [venueData, setVenueData] = useState(null);
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { item_category, name } = useParams();

  useEffect(() => {
    // Retrieve venueData from localStorage
    const token = localStorage.getItem("venueData");
    if (token) {
      setVenueData(JSON.parse(token));
    }

    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(items);
  }, []);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    }, 0);
  
    return () => clearTimeout(timeoutId);
  }, [location]);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
  }, [location]);

  const getProductData = async () => {
    let payload = { item_name: name };

    console.log(payload);
    try {
      const response = await handleGetProductDetails(payload);

      if (response) {
        console.log(response);
        //toast.success("Order has been Created!");
        setProduct(response.data.data);
        setLoading(false);
      } else {
        toast.warning("Failed to get Product Details");
        setLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      toast.error("Failed to get Product Details");
      setLoading(false);
    }
  };
  const handleBack = () => {
    if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate(`/`);
    }
  };

  useEffect(() => {
    getProductData();
  }, []);

  const handleBookNow = () => {
    const updatedCartItems = [...cartItems];
    const existingItemIndex = updatedCartItems.findIndex(
      (item) => item.name === name
    );
    if (existingItemIndex !== -1) {
      updatedCartItems[existingItemIndex].quantity += 1;
    } else {
      updatedCartItems.push({
        name,
        price: product.rate,
        image: product.image,
        item_category,
        quantity: 1,
      });
    }

    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));

    window.history.back();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Error: venue not found</div>;
  }

  return (
    <>
      <Navbar />
      <div>
        <header>
          <div className="flex-grow ">
            <div className="col-lg-8 w-full cart-wrap ml-5  py-8 px-6">
              <button onClick={handleBack} className="backBtn ">
                <img
                  className="rounded-full nav-profile-pic"
                  src={BackButton}
                  alt="ProfileImage"
                />
                Back
              </button>
            </div>
          </div>
          <div className="flex justify-between gap-3 items-center p-8 header-section">
            <h1 className="text-3xl font-bold text-start ml-5 lg:ml-20  mb-1 mt-4 relative venueDetailsHeading">
              {venueData.data.venue_name}
              <br />
              <h6 className="relative text-sm"></h6>
            </h1>

            <button
              onClick={handleBookNow}
              className="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-20 bookingButton"
            >
              Add to Cart
            </button>
          </div>
        </header>

        <div className=" mx-auto  flex flex-col justify-center">
          <>
            <div className="lg:flex lg:flex-row lg:flex-wrap ml-5 lg:ml-20 mr-5 lg:gap-4">
              <img
                src={
                  "https://bookadmin.thecage.com.sg" +
                  product.image[0].file_url
                }
                alt="product"
                className="w-full h-full mb-2 md:w-1/2 lg:w-2/5"
                style={{ height: "400px" }}
              />
              {product.image[1] && (
                <>
                  <img
                    src={
                      "https://bookadmin.thecage.com.sg" +
                      product.image[1].file_url
                    }
                    alt="product"
                    className="w-full h-full mb-2 md:w-1/2 lg:w-2/5"
                    style={{ height: "400px" }}
                  />
                  {product.image[2] && (
                    <div className="flex flex-col">
                      <img
                        src={
                          "https://bookadmin.thecage.com.sg" +
                          product.image[2].file_url
                        }
                        alt="product"
                        className="w-full h-1/2 mb-2 md:w-1/2 lg:w-40"
                        style={{ maxWidth: "100%", height: "200px" }}
                      />
                      {product.image[3] && (
                        <img
                          src={
                            "https://bookadmin.thecage.com.sg" +
                            product.image[3].file_url
                          }
                          alt="product"
                          className="w-full h-1/2 mb-2 md:w-1/2 lg:w-40"
                          style={{ maxWidth: "100%", height: "200px" }}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="ml-5 lg:ml-20">
              <h1 className="font-bold">{product.name}</h1>
              <p className="text-gray-700 text-xs mb-4">
                ₹ {product.rate}/hour
              </p>
              <hr className="mr-14"></hr>
            </div>
          </>
          <div className="mt-8 ml-5 lg:ml-20">
            <p
              className="mr-5"
              dangerouslySetInnerHTML={{
                __html: product.description,
              }}
            />
            <Row justify="start">
              <Col span={6} className="p-4">
                <div className="flex">
                  <div>
                    <img src={batIcon} className="mr-2" />
                  </div>
                  <div>
                    <p className="font-bold">Singapore Cricket Association</p>
                    <p>home ground affiliation possible.</p>
                  </div>
                </div>
              </Col>
              <Col span={6} className="p-4">
                <div className="flex">
                  <div>
                    <img src={batIcon} className="mr-2" />
                  </div>
                  <div>
                    <p className="font-bold">The Cage Cricket T30 League</p>
                    <p>(day cricket)</p>
                  </div>
                </div>
              </Col>
              <Col span={6} className="p-4">
                <div className="flex">
                  <div>
                    <img src={batIcon} className="mr-2" />
                  </div>
                  <div>
                    <p className="font-bold">The Cage Cricket T30 Leagu</p>
                    <p>(night cricket) Information</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="flex justify-between ml-5 lg:ml-20 mt-12 mb-5">
          <div>
            <p className="mb-4">For Details Please Contact:</p>
            <div className="flex">
              <div>
                <img src={Contact} className="mr-2 mb-4" />
              </div>
              <div>
                <p>Senthil Kumar</p>
              </div>
            </div>
            <div className="flex">
              <div>
                <img src={Email} className="mr-2 mb-4" />
              </div>
              <div>
                <p>cricket@thecage.com.sg</p>
              </div>
            </div>
            <div className="flex">
              <div>
                <img src={Phone} className="mr-2 mb-4" />
              </div>
              <div>
                <p>+6590098056</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemDetailsPage;
