import React, { useState, useEffect } from "react";
import { ArrowRight, Eye, EyeOff } from "lucide-react"; // Import Eye and EyeOff icons
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GoogleLogo from "../../assets/images/google_logo.svg";
import Logo from "../../assets/images/logoknox.png";
import { GLogin, signIn } from "../../handlers/authentication/api";
import Navbar from "../../component/common/Navbar";
import RightBanner from "./RightBanner";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useLocation } from 'react-router-dom';

const SignIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibilitys
  const location = useLocation();

  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isPasswordValid = password.length >= 6;

    setIsFormValid(isEmailValid && isPasswordValid);
  }, [email, password]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [location]);

  useEffect(() => {
    window.history.scrollRestoration = "manual";
window.scrollTo(0, 0);
  },[location]);


  const handleSignIn = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      setError("Please enter a valid email and password");
      return;
    }

    try {
      const data = await signIn(email, password);
      if (data.token) {
        localStorage.setItem("token", data.token);
        setIsLoggedIn(true);
        toast.success("Successfully Log In");
      } else {
        throw new Error("User Not Found");
      }
    } catch (error) {
      console.error("Error signing in:", error.message);
      toast.warning(error.message);
    }
  };

  const handleGoogleSignInSuccess = async (response) => {
    console.log(response);
    const decoded = jwtDecode(response.credential);

    console.log(decoded);

    const transformedObject = {
      data: {
        id: decoded.sub, // 'sub' from original is mapped to 'id'
        email: decoded.email,
        verified_email: decoded.email_verified, // 'email_verified' renamed to 'verified_email'
        name: decoded.name,
        given_name: decoded.given_name,
        family_name: decoded.family_name,
        picture: decoded.picture,
        hd: decoded.iss, // 'iss' from original is mapped to 'hd'
      },
      provider: "google", // Add static 'provider' key
    };

    console.log(transformedObject);

    const res = await GLogin(transformedObject);

    localStorage.setItem("token", res.data.token);
    setIsLoggedIn(true);
    toast.success("Successfully Logged In with Google");
  };
  const handleGoogleSignInError = (error) => {
    console.error("Error signing in with Google:", error);
    toast.error("Google Sign-In failed");
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  const handleRedirect = () => {
    navigate("/");
  };


  return (
    <>
      {/* <Navbar isLoggedIn={isLoggedIn} /> */}
      <section className="min-h-screen flex items-stretch justify-center signup">
        <div className="app-container">
          <div className="w-full signup-form-container p-10">
            <div className="signUpLogo">
              <img
                src={Logo}
                alt="Logo"
                className="top-0 left-0"
                onClick={handleRedirect}
              />
            </div>
            <div className="form-body xl:p-20">
              <h2 className="text-2xl font-bold leading-tight text-black signin">
                Sign in to your account
              </h2>
              <div className="mt-4 space-y-3">
                <GoogleLogin
                  onSuccess={handleGoogleSignInSuccess}
                  onError={handleGoogleSignInError}
                  render={(renderProps) => (
                    <button
                      type="button"
                      className="button relative inline-flex w-full items-center justify-center gap-3 rounded-md border googleSignup border-gray-800 px-3 py-2 font-semibold text-gray-700 transition-all duration-50 hover:bg-gray-100 hover:text-black focus:bg-gray-100 focus:text-black focus:outline-none text-sm"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <img
                        src={GoogleLogo}
                        alt="Google Sign Up"
                        className="google_logo"
                        height="30px"
                        width="30px"
                      />
                      Login with Google
                    </button>
                  )}
                />
              </div>
              <form onSubmit={handleSignIn} className="mt-8">
                <div className="space-y-5">
                  <div>
                    <label
                      htmlFor="email"
                      className="text-base font-medium text-gray-900"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type="email"
                        placeholder="Email"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="password"
                        className="text-base font-medium text-gray-900"
                      >
                        Password
                      </label>
                    </div>
                    <div className="mt-2 flex justify-end items-center">
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="password-toggle"
                      >
                        {showPassword ? (
                          <EyeOff size={20} />
                        ) : (
                          <Eye size={20} />
                        )}
                      </button>
                      <input
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                      ></input>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={!isFormValid}
                      className={`inline-flex w-full items-center justify-center rounded-md px-3.5 py-2.5 font-semibold leading-7 text-white redBtn hover:bg-black/80 ${!isFormValid && "opacity-50 cursor-not-allowed"
                        }`}
                    >
                      Get started <ArrowRight className="ml-2" size={16} />
                    </button>
                  </div>
                  {error && <p className="text-red-500">{error}</p>}
                </div>
              </form>
              <div className="flex justify-center mt-4 text-center ">
                <Link
                  to="/sign-in/forgot-password"
                  className="text-gray-500 font-semibold no-underline inline-block w-40"
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="flex justify-center mt-4 text-center signin-btn">
                <p className="font-semibold text-lg text-gray-600 text-p">
                  Don&apos;t have an account?
                  <Link
                    to="/sign-up"
                    className=" text-red-500 inline-block no-underline ml-3 hover:text-black"
                  >
                    Create Account
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <RightBanner />
        </div>
      </section>
    </>
  );
};

export default SignIn;
