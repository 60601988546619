import React, { useState, useEffect } from "react";
import Navbar from "../../component/common/NavbarProfile";

import AccessoryCard from "./AccessoryCard"; // Import the memoized AccessoryCard component
import { getProducts } from "../../handlers/common/api";
import { Button } from "antd";
import BackButton from "../../assets/images/backBtn.svg";
import { useNavigate, useLocation } from "react-router-dom";

const AccessoriesPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [venueData, setVenueData] = useState(null);
  const [rentalItems, setRentalItems] = useState([]);
  const [consumableItems, setConsumableItems] = useState([]);
  const [sp00Items, setSp00Items] = useState([]);
  const [showAll, setShowAll] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.history.scrollRestoration = "manual";
      window.scrollTo(0, 0);
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [location]);
  useEffect(() => {
    console.log("hlo trigger")
    window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
  }, [location]);


  useEffect(() => {
    // window.history.scrollRestoration = "manual";
    window.scrollTo(0, 0);
    const token = localStorage.getItem("venueData");
    if (token) {
      setVenueData(JSON.parse(token));
    }

    const items = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(items);
  }, []);

  const fetchRentalDetails = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Rental" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      console.log(data, "internal data 2")

      const arr = [];
      const optionList = data.data;
      console.log(optionList, "optionlist")
      const filterinternal = optionList.filter(item => item.is_internal === 0);
      console.log(filterinternal, "filter internal");
      for (let i = 0; i < filterinternal?.length; i++) {
        if (filterinternal[i].price !== 0) {
          arr.push({
            name: filterinternal[i].name,
            value: filterinternal[i].name,
            label: filterinternal[i].name,
            price: filterinternal[i].price,
            image: filterinternal[i].image,
            per_minuts_price: filterinternal[i].per_minuts_price,
            item_category: "Rental",
          });
        }
      }
      setRentalItems(arr);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  const fetchConsumableDetails = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Products" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      console.log(data, "internal data 3 ")

      const arr = [];
      const optionList = data.data;
      const filterinternal = optionList.filter(item => item.is_internal === 0);
      console.log(filterinternal, "filter internal");
      for (let i = 0; i < filterinternal?.length; i++) {
        if (filterinternal[i].price !== 0) {
          arr.push({
            name: filterinternal[i].name,
            item_name: filterinternal[i].item_name,
            value: filterinternal[i].name,
            label: filterinternal[i].name,
            price: filterinternal[i].price,
            image: filterinternal[i].image,
            per_minuts_price: filterinternal[i].per_minuts_price,
            item_category: "Products",
          });
        }
      }
      setConsumableItems(arr);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  const fetchSP002Details = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({ item_group: "Services Products 002" }),
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(`${getProducts}`, options);
      if (!response.ok) {
        throw new Error("Failed to fetch venue details");
      }
      const data = await response.json();

      console.log(data, "is internal data");

      const arr = [];
      const optionList = data.data;
      const filterinternal = optionList.filter(item => item.is_internal === 0);
      console.log(filterinternal, "filter internal");
      for (let i = 0; i < filterinternal?.length; i++) {
        if (filterinternal[i].price !== 0) {
          arr.push({
            name: filterinternal[i].name,
            value: filterinternal[i].name,
            label: filterinternal[i].name,
            price: filterinternal[i].price,
            image: filterinternal[i].image,
            per_minuts_price: filterinternal[i].per_minuts_price,
            item_category: "services_products_002",
          });
        }
      }
      setSp00Items(arr);
    } catch (error) {
      console.error("Error fetching venue details:", error);
    }
  };

  const checkIfMobile = () => {
    setIsMobileView(window.innerWidth <= 768);
  };

  const handleSeeMore = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    // fetchRentalDetails();
    fetchConsumableDetails();
    // fetchSP002Details();
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const handleProceedToCheckout = () => {
    navigate("/viewcart");
  };
  const handleBack = () => {
    if (location.key !== "default") {
      navigate(-1);
    } else {
      navigate(`/`);
    }
  };

  return (
    <>
      <Navbar />
      <div className="max-w-full justify-between items-center py-4 px-6 lg:px-20">
        <header className="mb-4 relative">
          <div className="flex flex-col md:flex-row justify-between">
            <div className="col-lg-8 w-full cart-wrap">
              <button onClick={handleBack} className="backBtn ">
                <img
                  className="rounded-full nav-profile-pic"
                  src={BackButton}
                  alt="ProfileImage"
                />
                Back
              </button>

              <div className="">
                <div className="flex">
                  <div>
                    <h1 className="text-3xl mt-4 font-bold text-start mb-0">
                      {venueData?.data?.venue_name}
                    </h1>
                    <p className="mt-0 text-slate-500 text-xs">
                      {venueData?.data?.address}
                    </p>
                  </div>
                  <div className="mt-4 px-6">
                    {!isMobileView && (
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleProceedToCheckout}
                      >
                        Proceed to Checkout
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {isMobileView && (
                <Button
                  onClick={handleProceedToCheckout}
                  className="cartBtn"
                  style={{ position: "fixed", right: "20px", zIndex: 1000 }}
                >
                  <span>Proceed to Checkout</span>
                </Button>
              )}
            </div>
          </div>
        </header>
        <div className="mt-8">
          <div className="flex justify-between">
            <div>
              <h1 className="font-bold text-4xl">Add Accessories</h1>
            </div>
            <div>
              {rentalItems.length > 3 && (
                <>
                  {/* <div className=" ">
                    <button onClick={handleSeeMore} className="text-red-500">
                      {showAll ? "See Less" : "See More"}
                    </button>
                  </div> */}
                </>
              )}
            </div>
          </div>
          <div>
            {rentalItems.length > 0 && (
              <h1 className="font-bold text-2xl mt-2 mb-4">Rental Items</h1>
            )}{" "}
            <div className="flex flex-wrap justify-center sm:justify-start lg:justify-start mb-10">
              {rentalItems
                .slice(0, showAll ? rentalItems.length : 3)
                .map((item, index) => (
                  <AccessoryCard
                    key={index}
                    {...item}
                    nonrental={false}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    className="lg:w-1/4 md:w-1/2 sm:w-full"
                  />
                ))}
            </div>
          </div>{" "}
          {consumableItems.length > 0 && (
            <h1 className="font-bold text-2xl mb-4">Consumable Items</h1>
          )}{" "}
          <div>
            <div className="flex flex-wrap justify-center sm:justify-start lg:justify-start mb-10">
              {consumableItems
                .slice(0, showAll ? consumableItems.length : 3)
                .map((item, index) => (
                  <AccessoryCard
                    key={index}
                    {...item}
                    nonrental={true}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    className="lg:w-1/4 md:w-1/2 sm:w-full"
                  />
                ))}
            </div>
          </div>
          {sp00Items.length > 0 && (
            <h1 className="font-bold text-2xl mb-4">Service Items</h1>
          )}{" "}
          <div>
            <div className="flex flex-wrap justify-center sm:justify-start lg:justify-start mb-10">
              {sp00Items
                .slice(0, showAll ? sp00Items.length : 3)
                .map((item, index) => (
                  <AccessoryCard
                    key={index}
                    {...item}
                    nonrental={true}
                    cartItems={cartItems}
                    setCartItems={setCartItems}
                    className="lg:w-1/4 md:w-1/2 sm:w-full"
                  />
                ))}
            </div>
          </div>
          <div className=" flex ">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded z-10"
              onClick={handleProceedToCheckout}
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessoriesPage;
