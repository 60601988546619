import React from "react";
import Banner from "../../assets/images/SignInBanner.png";

function RightBanner() {
  return (
    <div className="image-container">
      <img src="https://images.squarespace-cdn.com/content/v1/5f8c84ed5b07537803ad9dd1/c5763e61-d4a8-4c48-bf86-bf7ea2b6f682/Joel.png" alt="Sign In Banner" className="SignUp-image" />
    </div>
  );
}

export default RightBanner;
